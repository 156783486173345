import request from "../../utils/request"

// 设备详情首页
export function deatails(id = '', time = '') {
    const url = `api/deatails/index`;
    return request.post(url, {
        id,
        time
    });
}

// 使用时间接口
export function setUP(id) {
    const url = `api/deatails/setUP`;
    return request.post(url, {
        id,
    });
}

// 修改时间更改接口
export function setUPs(id, monday, tuesday, wednesday, thursday, friday, saturday, sunday) {
    const url = `api/deatails/setUPs`;
    return request.post(url, {
        id,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday
    });
}

// 设备详情接口
export function details(id) {
    const url = `api/device/details`;
    return request.post(url, {
        id
    });
}