<template>
  <!-- 报警设置 -->
  <div class="warning">
    <div class="warningtop">报警通知设置</div>
    <div class="warningall">
      <div class="warningmain">
        <div>数量感应器：</div>
        <el-switch
          v-model="value1"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="2"
          @change="active_text1"
        >
        </el-switch>
        <div>开启</div>
      </div>
      <div class="warningmain">
        <div>水泵：</div>
        <el-switch
          v-model="value2"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="2"
          @change="active_text2"
        >
        </el-switch>
        <div>开启</div>
      </div>
      <!-- <div class="warningmain">
        <div>风扇：</div>
        <el-switch
          v-model="fengshanValue"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="2"
          @change="active_fengshan"
        >
        </el-switch>
        <div>开启</div>
      </div> -->
      <div class="warningmain">
        <div>泵1报警：</div>
        <el-switch
          v-model="value3"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="2"
          @change="active_text3"
        >
        </el-switch>
        <div>开启</div>
      </div>
      <div class="warningmain">
        <div>泵2报警：</div>
        <el-switch
          v-model="value4"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="2"
          @change="active_text4"
        >
        </el-switch>
        <div>开启</div>
      </div>
      <div class="warningmain">
        <div>系统执行异常：</div>
        <el-switch
          v-model="value5"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="2"
          @change="active_text5"
        >
        </el-switch>
        <div>开启</div>
      </div>
      <div class="warningmain">
        <div>泵1液位低：</div>
        <el-switch
          v-model="value6"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="2"
          @change="active_text6"
        >
        </el-switch>
        <div>开启</div>
      </div>
      <div class="warningmain">
        <div>泵2液位低：</div>
        <el-switch
          v-model="value7"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="2"
          @change="active_text7"
        >
        </el-switch>
        <div>开启</div>
      </div>
      <div class="warningmain">
        <div>长时间未运行：</div>
        <el-switch
          v-model="value8"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-value="1"
          inactive-value="2"
          @change="active_text8"
        >
        </el-switch>
        <div>开启</div>
      </div>
      <!-- 泵1报警液位置 -->
      <div class="warningmain">
        <div>泵1报警液位：</div>
        <div class="weihzi1">{{ input11 }}</div>
        <div class="weizhi2" @click="del1">设置</div>
      </div>
      <!-- 泵2报警液位置 -->
      <div class="warningmain">
        <div>泵2报警液位：</div>
        <div class="weihzi1">{{ input22 }}</div>
        <div class="weizhi2" @click="del2">设置</div>
      </div>
      <!-- 未运行报警时长： -->
      <div class="warningmain">
        <div>未运行报警时长：</div>
        <div class="weihzi1">{{ input33 }}天</div>
        <div class="weizhi2" @click="del3">设置</div>
      </div>
    </div>
    <el-dialog
      title="泵1报警液位"
      :visible.sync="dialogVisible1"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="warnbao">
        <div>请输入泵1报警液位：</div>
        <el-input
          v-model="input1"
          style="width: 30%; margin-top: 30px"
          @keyup.enter.native="sure1"
        ></el-input>
        <span class="fanwei" ref="fanwei">范围0-1000</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="sure1">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="泵2报警液位"
      :visible.sync="dialogVisible2"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="warnbao">
        <div>请输入泵2报警液位：</div>
        <el-input
          v-model="input2"
          style="width: 30%; margin-top: 30px"
          @keyup.enter.native="sure2"
        ></el-input>
        <span class="fanwei" ref="fanwei">范围0-1000</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="sure2">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="未运行报警时长"
      :visible.sync="dialogVisible3"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="warnbao">
        <div>请输入未运行报警时长：</div>
        <el-input
          v-model="input3"
          style="width: 30%; margin-top: 30px"
          @keyup.enter.native="sure3"
        ></el-input>
        <span class="fanwei" ref="fanwei">范围0-1000</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click ="sure3">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { details } from "../../api/detail/details";
import { setuperror } from "../../api/equip/device";
export default {
  name: "warning",
  props: {},
  data() {
    return {
      value1: "0",
      value2: "0",
      value3: "0",
      value4: "0",
      value5: "0",
      value6: "0",
      value7: "0",
      value8: "0",
      fengshanValue:'0',
      dialogVisible2: false,
      dialogVisible1: false,
      dialogVisible3: false,
      input1: "",
      input2: "",
      input3: "",
      input11: "",
      input22: "",
      input33: "",
      sn: "",
    };
  },
  created() {
    details(this.$store.state.sheid).then((res) => {
      console.log("报警");
      console.log(res.alarmSettings[0]);
      this.sn = res.details[0].sn;
      this.value1 = res.alarmSettings[0].slgyq;
      this.value2 = res.alarmSettings[0].waterPump;
      this.value3 = res.alarmSettings[0].berror;
      this.value4 = res.alarmSettings[0].berrors;
      this.value5 = res.alarmSettings[0].programError;
      this.value6 = res.alarmSettings[0].leveld;
      this.value7 = res.alarmSettings[0].levelsd;
      this.value8 = res.alarmSettings[0].csjwyxbj;
      this.input11 = res.alarmSettings[0].leveldz;
      this.input22 = res.alarmSettings[0].levelsdz;
      this.input33 = res.alarmSettings[0].weiyunxingsjsz;
    });
  },
  methods: {
    active_text1() {
      console.log(this.value1);
      let str = {
        slgyq: this.value1,
        sn: this.sn,
      };
      console.log(str);
      this.shezhi(str);
    },
    active_text2() {
      console.log(this.value2);
      console.log(this.value1);
      let str = {
        waterPump: this.value2,
        sn: this.sn,
      };
      console.log(str);
      this.shezhi(str);
    },
    active_text3() {
      console.log(this.value3);
      let str = {
        berror: this.value3,
        sn: this.sn,
      };
      console.log(str);
      this.shezhi(str);
    },
    active_text4() {
      console.log(this.value4);
      let str = {
        berrors: this.value4,
        sn: this.sn,
      };
      console.log(str);
      this.shezhi(str);
    },
    active_text5() {
      console.log(this.value5);
      let str = {
        programError: this.value5,
        sn: this.sn,
      };
      console.log(str);
      this.shezhi(str);
    },
    active_text6() {
      console.log(this.value6);
      let str = {
        leveld: this.value6,
        sn: this.sn,
      };
      console.log(str);
      this.shezhi(str);
    },
    active_text7() {
      console.log(this.value7);
      let str = {
        levelsd: this.value7,
        sn: this.sn,
      };
      console.log(str);
      this.shezhi(str);
    },
    active_text8() {
      console.log(this.value8);
      let str = {
        csjwyxbj: this.value8,
        sn: this.sn,
      };
      console.log(str);
      this.shezhi(str);
    },
    // 风扇设置
    // active_fengshan(){

    // },
    sure1() {
      var numReg = /^[0-9]*$/;
      var numRe = new RegExp(numReg);
      if (!numRe.test(this.input1)) {
        this.$refs.fanwei.style.color = "red";
      } else {
        if (this.input1 > 1000 || this.input1 < 0) {
          this.$refs.fanwei.style.color = "red";
        } else {
          this.input11 = JSON.parse(JSON.stringify(this.input1));
          let str = {
            leveldz: this.input1,
            sn: this.sn,
          };
          // console.log(str);
          // this.dialogVisible1 = false;
          this.shezhi(str);
          // this.$message({
          //   message: "恭喜你，设置成功！",
          //   type: "success",
          // });
        }
      }
    },
    sure2() {
      var numReg = /^[0-9]*$/;
      var numRe = new RegExp(numReg);
      if (!numRe.test(this.input2)) {
        this.$refs.fanwei.style.color = "red";
      } else {
        if (this.input2 > 1000 || this.input2 < 0) {
          this.$refs.fanwei.style.color = "red";
        } else {
          this.input22 = JSON.parse(JSON.stringify(this.input2));
          let str = {
            levelsdz: this.input2,
            sn: this.sn,
          };
          this.shezhi(str);
          // console.log(str);
          // this.$message({
          //   message: "恭喜你，设置成功！",
          //   type: "success",
          // });
          // this.dialogVisible2 = false;
        }
      }
    },
    sure3() {
      var numReg = /^[0-9]*$/;
      var numRe = new RegExp(numReg);
      if (!numRe.test(this.input3)) {
        this.$refs.fanwei.style.color = "red";
      } else {
        if (this.input3 > 1000 || this.input3 < 0) {
          this.$refs.fanwei.style.color = "red";
        } else {
          this.input33 = JSON.parse(JSON.stringify(this.input3));
          let str = {
            weiyunxingsjsz: this.input3,
            sn: this.sn,
          };
          this.shezhi(str);
          // console.log(str);
          // this.$message({
          //   message: "恭喜你，设置成功！",
          //   type: "success",
          // });
          // this.dialogVisible3 = false;
        }
      }
    },
    shezhi(data) {
      setuperror(data).then((res) => {
        console.log(res);
        if (res.resutlt == 0) {
          details(this.$store.state.sheid).then((res) => {
            console.log("报警");
            console.log(res.alarmSettings[0]);
            this.sn = res.details[0].sn;
            this.value1 = res.alarmSettings[0].slgyq;
            this.value2 = res.alarmSettings[0].waterPump;
            this.value3 = res.alarmSettings[0].berror;
            this.value4 = res.alarmSettings[0].berrors;
            this.value5 = res.alarmSettings[0].programError;
            this.value6 = res.alarmSettings[0].leveld;
            this.value7 = res.alarmSettings[0].levelsd;
            this.value8 = res.alarmSettings[0].csjwyxbj;
            this.input11 = res.alarmSettings[0].leveldz;
            this.input22 = res.alarmSettings[0].levelsdz;
            this.input33 = res.alarmSettings[0].weiyunxingsjsz;
          });
          this.$message.success("设置成功");
          this.dialogVisible1 = false;
          this.dialogVisible2 = false;
          this.dialogVisible3 = false;
        } else {
          details(this.$store.state.sheid).then((res) => {
            console.log("报警");
            console.log(res.alarmSettings[0]);
            this.sn = res.details[0].sn;
            this.value1 = res.alarmSettings[0].slgyq;
            this.value2 = res.alarmSettings[0].waterPump;
            this.value3 = res.alarmSettings[0].berror;
            this.value4 = res.alarmSettings[0].berrors;
            this.value5 = res.alarmSettings[0].programError;
            this.value6 = res.alarmSettings[0].leveld;
            this.value7 = res.alarmSettings[0].levelsd;
            this.value8 = res.alarmSettings[0].csjwyxbj;
            this.input11 = res.alarmSettings[0].leveldz;
            this.input22 = res.alarmSettings[0].levelsdz;
            this.input33 = res.alarmSettings[0].weiyunxingsjsz;
          });
          this.$message.error(res.message);
        }
      });
    },
    // 设置
    del1() {
      this.dialogVisible1 = true;
      // this.input1 = 1000;
      if (this.$refs.fanwei) {
        console.log(1235);
        this.$refs.fanwei.style.color = "#adadad";
      }
    },
    del2() {
      this.dialogVisible2 = true;
      // this.input2 = 1000;
      if (this.$refs.fanwei) {
        console.log(1235);
        this.$refs.fanwei.style.color = "#adadad";
      }
    },
    del3() {
      this.dialogVisible3 = true;
      // this.input3 = 1000;
      if (this.$refs.fanwei) {
        console.log(1235);
        this.$refs.fanwei.style.color = "#adadad";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.warning {
  height: 413px;
  background: white;
  padding: 40px;
  .warningtop {
    color: rgba(16, 16, 16, 100);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .warningall {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 250px;
    align-content: flex-start;
  }
  .warningmain {
    width: 33%;
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-bottom: 20px;
    div:nth-of-type(1) {
      width: 192px;
      text-align: right;
    }
    div:nth-of-type(3) {
      margin-left: 12px;
    }
    .weizhi1 {
      width: 70px;
      text-align: center;
    }
    .weizhi2 {
      color: rgba(31, 109, 249, 100);
      cursor: pointer;
    }
  }
  .warnbao {
    font-size: 24px;
    /deep/.el-input__inner {
      font-size: 20px;
    }
  }
  /deep/.el-dialog__title {
    font-size: 24px;
  }
  .fanwei {
    margin-left: 10px;
    color: #adadad;
  }
  /deep/.el-dialog__title {
    font-size: 28px;
    font-weight: 600;
  }
  /deep/.el-button {
    font-size: 20px;
  }
}
</style>
