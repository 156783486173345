<template>
  <div class="usetime">
    <div class="usemain">
      <div class="usetop">
        <div></div>
        <div>周一</div>
        <div>周二</div>
        <div>周三</div>
        <div>周四</div>
        <div>周五</div>
        <div>周六</div>
        <div>周日</div>
      </div>
      <!-- 泵一泵二都遍历去循环数组，有状态就显示 -->
      <div class="usezhu">
        <div>泵一</div>
        <div
          v-for="(item, index) in status1"
          :key="index"
          @click="change(item)"
        >
          <i
            class="el-icon-check"
            style="color: green; font-size: 24px"
            v-if="item.value == 1"
          >
          </i>
        </div>
      </div>
      <div class="usezhu">
        <div>泵二</div>
        <div
          v-for="(item, index) in status2"
          :key="index + 'a'"
          @click="change1(item)"
        >
          <i
            class="el-icon-check"
            style="color: green; font-size: 24px"
            v-if="item.value == 1"
          >
          </i>
        </div>
      </div>
      <div class="usebot"></div>
    </div>
  </div>
</template>

<script>
import { details } from "../../api/detail/details";
import { usagetime } from "../../api/equip/device";
export default {
  name: "usetime",
  data() {
    return {
      status: [],
      status1: [],
      status2: [],
      shezhi: "",
      sn: "",
    };
  },
  created() {
    console.log(this.$store.state.sheid);
    details(this.$store.state.sheid).then((res) => {
      console.log(res);
      this.sn = res.details[0].sn;
      // 获取数组
      this.status = res.usageTime;
      this.status1 = this.status.slice(0, 7);
      this.status2 = this.status.slice(7, 14);
      (this.status1[0].date = "one"),
        (this.status1[1].date = "two"),
        (this.status1[2].date = "three"),
        (this.status1[3].date = "four"),
        (this.status1[4].date = "five"),
        (this.status1[5].date = "six"),
        (this.status1[6].date = "seven"),
        (this.status2[0].date = "one"),
        (this.status2[1].date = "two"),
        (this.status2[2].date = "three"),
        (this.status2[3].date = "four"),
        (this.status2[4].date = "five"),
        (this.status2[5].date = "six"),
        (this.status2[6].date = "seven");
      // console.log(this.status);
      // console.log(this.status1)
      // console.log(this.status2)
    });
  },
  methods: {
    change(item) {
      //console.log(item);
      if (item.value == 1) {
        this.$confirm("此操作将取消泵一, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // 确认取消选择
            let str = {
              sn: this.sn,
              day: item.day,
              value: 0,
            };
            usagetime(str).then((res) => {
              console.log(res);
              if (res.resutlt == 0) {
                // 重新获取列表
                details(this.$store.state.sheid).then((res) => {
                  // console.log(res);
                  this.sn = res.details[0].sn;
                  this.status = res.usageTime;
                  this.status1 = this.status.slice(0, 7);
                  this.status2 = this.status.slice(7, 14);
                  (this.status1[0].date = "one"),
                    (this.status1[1].date = "two"),
                    (this.status1[2].date = "three"),
                    (this.status1[3].date = "four"),
                    (this.status1[4].date = "five"),
                    (this.status1[5].date = "six"),
                    (this.status1[6].date = "seven"),
                    (this.status2[0].date = "one"),
                    (this.status2[1].date = "two"),
                    (this.status2[2].date = "three"),
                    (this.status2[3].date = "four"),
                    (this.status2[4].date = "five"),
                    (this.status2[5].date = "six"),
                    (this.status2[6].date = "seven");
                  //console.log(this.status);
                });
                this.$message.success("取消成功");
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
        //this.$message.success("当前使用的就是泵一，不需要切换");
      } else {
        // 选择
        // console.log(this.status2, "123");
        // console.log(item);
        // console.log("测试----");
        let index = "";
        for (let i = 0; i < this.status2.length; i++) {
          if (this.status2[i].date === item.date) {
            index = i;
          }
        }
        // console.log(index, "测试----");
        if (this.status2[index].value == 1) {
          this.$message.warning("同一天只能选择一个泵");
          return false;
        }
        this.$confirm("此操作将选择泵一, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let str = {
              sn: this.sn,
              day: item.day,
              value: 1,
            };
            usagetime(str).then((res) => {
              console.log(res);
              if (res.resutlt == 0) {
                // 重新获取列表
                details(this.$store.state.sheid).then((res) => {
                  // console.log(res);
                  this.sn = res.details[0].sn;
                  this.status = res.usageTime;
                  this.status1 = this.status.slice(0, 7);
                  this.status2 = this.status.slice(7, 14);
                  (this.status1[0].date = "one"),
                    (this.status1[1].date = "two"),
                    (this.status1[2].date = "three"),
                    (this.status1[3].date = "four"),
                    (this.status1[4].date = "five"),
                    (this.status1[5].date = "six"),
                    (this.status1[6].date = "seven"),
                    (this.status2[0].date = "one"),
                    (this.status2[1].date = "two"),
                    (this.status2[2].date = "three"),
                    (this.status2[3].date = "four"),
                    (this.status2[4].date = "five"),
                    (this.status2[5].date = "six"),
                    (this.status2[6].date = "seven");
                  // console.log(this.status);
                });
                this.$message.success("选择成功");
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消切换",
            });
          });
      }
    },
    change1(item) {
      // console.log(item);
      if (item.value == 1) {
        this.$confirm("此操作将取消泵二, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let str = {
              sn: this.sn,
              day: item.day,
              value: 0,
            };
            usagetime(str).then((res) => {
              console.log(res);
              if (res.resutlt == 0) {
                details(this.$store.state.sheid).then((res) => {
                  // console.log(res);
                  this.sn = res.details[0].sn;
                  this.status = res.usageTime;
                  this.status1 = this.status.slice(0, 7);
                  this.status2 = this.status.slice(7, 14);
                  (this.status1[0].date = "one"),
                    (this.status1[1].date = "two"),
                    (this.status1[2].date = "three"),
                    (this.status1[3].date = "four"),
                    (this.status1[4].date = "five"),
                    (this.status1[5].date = "six"),
                    (this.status1[6].date = "seven"),
                    (this.status2[0].date = "one"),
                    (this.status2[1].date = "two"),
                    (this.status2[2].date = "three"),
                    (this.status2[3].date = "four"),
                    (this.status2[4].date = "five"),
                    (this.status2[5].date = "six"),
                    (this.status2[6].date = "seven");
                  // console.log(this.status);
                });
                this.$message.success("取消成功");
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
        //this.$message.success("当前使用的就是泵二，不需要切换");
      } else {
        let index = "";
        for (let i = 0; i < this.status1.length; i++) {
          if (this.status1[i].date === item.date) {
            index = i;
          }
        }
        // console.log(index, "测试----");
        if (this.status1[index].value == 1) {
          this.$message.warning("同一天只能选择一个泵");
          return false;
        }

        this.$confirm("此操作将选择泵二, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let str = {
              sn: this.sn,
              day: item.day,
              value: 1,
            };
            usagetime(str).then((res) => {
              console.log(res);
              if (res.resutlt == 0) {
                details(this.$store.state.sheid).then((res) => {
                  // console.log(res);
                  this.sn = res.details[0].sn;
                  this.status = res.usageTime;
                  this.status1 = this.status.slice(0, 7);
                  this.status2 = this.status.slice(7, 14);
                  (this.status1[0].date = "one"),
                    (this.status1[1].date = "two"),
                    (this.status1[2].date = "three"),
                    (this.status1[3].date = "four"),
                    (this.status1[4].date = "five"),
                    (this.status1[5].date = "six"),
                    (this.status1[6].date = "seven"),
                    (this.status2[0].date = "one"),
                    (this.status2[1].date = "two"),
                    (this.status2[2].date = "three"),
                    (this.status2[3].date = "four"),
                    (this.status2[4].date = "five"),
                    (this.status2[5].date = "six"),
                    (this.status2[6].date = "seven");
                  // console.log(this.status);
                });
                this.$message.success("选择成功");
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.usetime {
  height: 413px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  .usemain {
    width: 720px;
    height: 190px;
  }
  .usetop {
    background: #e8e8e8;
    height: 60px;
    display: flex;
    font-size: 24px;
    div {
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: default;
    }
  }
  .usezhu {
    background: #e8e8e8;
    height: 60px;
    display: flex;
    div {
      width: 90px;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #dfdfdf;
      border-bottom: 1px solid #dfdfdf;
      background: white;
      cursor: pointer;
    }
    div:nth-last-child(1) {
      border-right: 1px solid #dfdfdf;
    }
    div:nth-of-type(1) {
      cursor: default;
    }
  }
}
</style>
